.site-carousel {
	background-color: $primary;
    // background: linear-gradient(180deg,#27dcfe,#13aff0);
    position: relative;

    .text-neon {
        /*
        text-shadow:
            0 0 6px rgba(202,228,225,0.92),
            0 0 30px rgba(202,228,225,0.34),
            0 0 12px rgba(30,132,242,0.52),
            0 0 21px rgba(30,132,242,0.92),
            0 0 34px rgba(30,132,242,0.78),
            0 0 54px rgba(30,132,242,0.92);
        */
        text-shadow: 0px 0px 10px $primary;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 290px;
        background: url(/images/bg-effect.png) top center;
        background-size: contain;
        // background-repeat: no-repeat;
        opacity: 0.075;

        // Để thao tác được với carousel
        // Không hiểu sao z-index: -1 như site-navbar không được
        pointer-events: none;
    }

    .nat-carousel-item {
		blockquote {
			max-width: 700px;
		}

		cite {
			color: rgba(#FFF, .5);
		}
	}

    .nat-carousel-indicators {
        [data-item-to] {
            width: 7px;
            height: 7px;
            margin: 2rem 12px;
            background: rgba(#FFF, .3);
    
            &.active {
                background: #FFF;
                animation: check 0.4s linear forwards;
            }
    
            &:hover {
                background: rgba(#FFF, .5);
            }
        }
    }
}


// Animation indicator
@keyframes check {
    50% {
        outline-color: #333;
        box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
    }

    100% {
        outline-color: #333;
        box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
    }
}
