// $primary: #13aff0; // lấy của Art Station
// $primary: #ee0033; // màu logo mới của Viettel
// $danger: hsl(354, 70%, 63%); // màu sáng hơn trên nền tối
// $warning: hsl(45, 100%, 43%); // vàng dịu hơn

// Màu Bootstrap
$danger: #d63384; // bs-pink
$success: #20c997; // bs-teal
// $warning: #d63384; // bs-pink

// $border-color: #6c757d; // $gray-600 (dark-theme dùng cái này, light-theme thì không dùng, dùng mặc định của Bootstrap)
