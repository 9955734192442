// Image gallery
.site-gallery {
	.gallery-item {
        // Ảnh
        img {
            transition: .3s all ease-in-out;
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        // Icon search
        .search-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 3;
            color: #FFF;
            font-size: 1.7rem;
            transition: .3s all ease;
            opacity: 0;
            visibility: hidden;
            transform: translate(-50%, -50%) scale(0.0);
        }

        &:hover {
            .search-icon {
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%) scale(1.0);
            }
        }

        // Overlay
        .overlay {
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(#000, .4);
            transition: .3s all ease-in-out;
            visibility: hidden;
            opacity: 0;
        }

        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
            }
        }
	}
}
